@use "sass:map";
@import "~@oup/shared-front-end/src/styles/themes/base/typography";
@import "~@oup/shared-front-end/src/styles/themes/base/colors";
@import "../../../styles/constants/colors";

.stepContent {
  width: 100%;
  color: map.get($base, black);

  h1 {
    font-family: $font-family-display-font;
    padding: 0;
  }

  h2 {
    margin: 0 0 2rem;
    font-size: 1.313rem;
    font-weight: $font-weight-400;
    letter-spacing: -1.7%;
    padding-left: 0;
  }

  .orgStaffContainer {
    display: flex;
    align-items: center;
    padding: 0.75rem 0.938rem;
    border-bottom: 1px solid map.get($primary, primary20);
    margin-top: 2rem;
    gap: 1rem;

    &:last-of-type {
      border-bottom: none;
    }

    .initialsContainer {
      display: flex;
      width: 3.125rem;
      height: 3.125rem;
      align-items: center;
      justify-content: center;
      border-radius: 100%;
      aspect-ratio: 1;
      background-color: #FB6D51;
      font-weight: bold;
      text-transform: uppercase;
    }

    .nameContainer {
      letter-spacing: -1.7%;

      .email {
        color: map.get($typography-colors, sub-text);
        font-size: 0.813rem;
      }

      .status {
        display: flex;
        align-items: center;
        font-size: 0.813rem;
        gap: 0.313rem;

        svg {
          width: 1rem;
        }
      }
    }
  }
}

.informationBox {
  display: flex;
  max-width: 21.875rem;
  flex-direction: column;
  padding: 1rem;
  border: 1px solid map.get($primary, primary20);
  border-radius: 8px;
  font-size: 1rem;
  line-height: 1.5rem;

  p:first-of-type {
    font-weight: 700;
  }

  p:nth-child(2) {
    margin-bottom: 0.5rem;
  }
}
