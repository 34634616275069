@import "~@oup/shared-front-end/src/styles/themes/base/sizes";

.register {
  font-size: $gap-5;
}

.notifyUser {
  text-align: center;
}

.wrapperIcon {
  width: 5rem;
  margin: 2rem auto 0;
}
