@import "~@oup/shared-front-end/src/styles/breakpoints";
@import "~@oup/shared-front-end/src/styles/themes/base/sizes";
@import "~@oup/shared-front-end/src/styles/themes/base/typography";
@import "../../../styles/constants/colors";


.chooseRoleStep {
  width: 100%;
  max-width: 40.5rem;
  display: flex;
  flex-direction: column;
  padding: $gap-6 $gap-5 0 $gap-5;
}

.heading {
  font-family: $font-family-display-font;
  padding: 0;
}

.subHeading {
  padding-left: 0;
}

.radioButtonGroupFieldset {
  padding: 0 $gap-1 $gap-5;
}

.radioButtonGroupContainer {
  display: flex;
  flex-flow: column;
  padding: $gap-5 0;
  gap: $gap-3;

  @media (min-width: $screen-width-480) {
    flex-flow: row wrap;

    > label {
      flex-basis: 0;
      flex-grow: 1;
    }
  }
}

.RadiobuttonImage {
  width: $icon-size-regular;
  height: $icon-size-regular;
}
