@use "sass:map";
@import "~@oup/shared-front-end/src/styles/themes/base/typography";
@import "~@oup/shared-front-end/src/styles/themes/base/sizes";

.stepContainer {
  display: flex;
  max-width: 40.5rem;
  flex-direction: column;
  padding: $gap-6 $gap-5;
  gap: $gap-5;

  .heading {
    padding: 0;
    font-family: $font-family-display-font;
  }

  .subHeading {
    padding-left: 0;
  }

  .productsContainer {
    height: auto;
    margin-top: $gap-4;
  }
}
