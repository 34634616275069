@import "~@oup/shared-front-end/src/styles/themes/base/sizes";
@import "~@oup/shared-front-end/src/styles/themes/base/typography";
@import "~@oup/shared-front-end/src/styles/breakpoints";

.modalContainer {
  padding: 1.5rem;

  .formTitle {
    font-size: $font-size-xlarge;
    font-weight: $font-weight-700;
  }

  .cardsContainer {
    display: flex;
    padding: 1.5rem 0;
    gap: $gap-5;

    section:first-of-type {
      width: 70%;
    }

    section:nth-of-type(2) {
      width: 30%;
    }
  }

  .inputContainer {
    padding-top: $gap-4;
  }

  .linkContainer {
    padding: $gap-4 0;

    a {
      margin-left: 5px;
    }
  }

  .boldText {
    font-weight: $font-weight-600;
  }

  .emptyStateChildrenContainer {
    padding: $gap-4;

    button {
      margin: auto;
      margin-bottom: $gap-4;
    }
  }

  @media screen and (max-width: $screen-width-1024) {
    .cardsContainer {
      flex-direction: column;

      section:first-of-type,
      section:nth-of-type(2) {
        width: 100%;
      }
    }
  }
}
