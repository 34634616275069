@import "~@oup/shared-front-end/src/styles/themes/base/typography";
@import "~@oup/shared-front-end/src/styles/themes/base/sizes";
@import "~@oup/shared-front-end/src/styles/themes/base/colors";
@import "../../styles/constants/breakpoints";
@import "../../styles/constants/colors";

.lockContentContainer {
  .headerContent {
    padding: $gap-4;
    border-bottom: $border-width-1 solid map-get($primary, primary20);

    p:first-of-type {
      margin-top: 2rem;
      font-size: $font-size-large;
      font-weight: $font-weight-600;
    }
  }

  .productDetailsContainer {
    display: flex;
    align-items: center;
    padding: $gap-4;

    .imageContainer {
      display: flex;
      width: $gap-6;
      height: 4rem;
      align-items: center;

      img {
        height: 100%;
        width: 100%;
        border: $border-width-1 solid map-get($primary, primary20);
        border-radius: $border-radius-small;
      }
    }

    .titleContainer {
      margin-left: $gap-4;
    }
  }

  .tabContainer {
    display: flex;
    margin: $gap-4;

    .tabText {
      display: flex;
      width: 8.5rem;
      justify-content: center;
      border: $border-width-1 solid map-get($primary, primary20);
      border-radius: $border-radius-small $border-radius-small $border-radius-none $border-radius-none;
      border-bottom: none;

      label {
        margin: $gap-0;
        font-weight: $font-weight-600;
      }
    }

    .line {
      width: calc(100% - 8.5rem);
      border-bottom: $border-width-1 solid map-get($primary, primary20);
    }
  }

  .unitsContainer{
    padding: $gap-0 $gap-4;

    .lockItem {
      position: relative;
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: flex-start;
      padding: $gap-2 $gap-0;

      .padlockContainer{
        position: absolute;
        left: $gap-4;
        display: flex;
        width: $icon-size-regular;
        fill: map-get($primary, primary100);
      }

      .previewContainer {
        position: absolute;
        display: flex;
        right: 0;
        padding: $gap-2 $gap-2 $gap-2 $gap-4;
      }

      label {
        width: 100%;
        padding-left: $gap-6;
        margin: $gap-0;
      }
    }
  }
}
.outerWarningModalContainer {
  position: fixed;
  z-index: 105;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  width: 100%;
  min-height: 100vh;
  justify-content: center;
  padding: 0;
  margin: 0;
  background-color: transparent;
  opacity: 1;
  pointer-events: none;
  transition: 0.3s background-color, z-index ease-in-out;

  &.open {
    z-index: 99999999;
    background-color: $contrast-light-hover;
    pointer-events: unset;
    visibility: initial;
  }

  .modalContainer {
    position: absolute;
    top: -60%;
    left: 50%;
    max-width: 40rem;
    background-color: $color-white;
    border-radius: 0.3125rem;
    transform: translate(-50%, -50%);
    transition: 0.3s top linear;

    &.animationFadeIn {
      top: 35%;
    }

    @media #{$xsMedia} {
      min-width: 20rem;
    }

    .modalHeader {
      display: flex;
      justify-content: flex-end;
      padding: 1.5rem;
      text-align: right;
    }

    .modalContent {
      text-align: center;

      .title {
        padding: 0 1.5rem 1.5rem;
        font-size: 2rem;
        font-weight: 900;

        @media #{$xsMedia} {
          font-size: 1.5rem;
        }
      }

      .subtitle {
        padding: 1.5rem;
        border-top: 1px solid map-get($secondary-colors, hub_light_grey);

        span {
          font-weight: 900;
        }
      }
    }

    .modalFooter {
      display: flex;
      justify-content: space-between;
      padding: 1.5rem;
    }
  }
  .closeIcon {
    height: 10px;
    width: 10px;
  }
}
