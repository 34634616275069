@use "sass:map";
@import "~@oup/shared-front-end/src/styles/themes/base/sizes";
@import "~@oup/shared-front-end/src/styles/themes/base/colors";

.headerContainer {
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    color: map.get($primary, primary100);
  }

  .linkContainer {
    padding-top: $gap-3;
  }
}
