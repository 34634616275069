@use "sass:map";
@import "~@oup/shared-front-end/src/styles/themes/base/sizes";
@import "~@oup/shared-front-end/src/styles/themes/base/colors";
@import "~@oup/shared-front-end/src/styles/themes/base/typography";
@import "~@oup/shared-front-end/src/styles/breakpoints";

.heading {
  font-family: $font-family-display-font;
  padding: 0 0 $gap-4 0;
}

.subHeading {
  padding-left: 0;
}

.registerOrgContentContainer {
  display: flex;
  max-width: 63.5rem;
  flex-direction: row;
  padding: $gap-6 $gap-5 $gap-6 $gap-5;
  gap: $gap-5;

  .orgSetupStep  {
    min-height: auto;
    padding: 0;

    >div {
      max-width: 100%;
    }

    .infoBannerContainer {
      margin-top: $gap-2;

      svg {
        align-self: flex-start;
      }

      .messageContainer {
        display: flex;
        flex-direction: column;

        a {
          margin-top: $gap-2;
        }
      }
    }
  }

  .orgSetupForm {
    display: flex;
    flex-direction: column;
    color: map.get($primary, primary100);
    gap: $gap-5;

    .fieldContainer {
      margin-top: $gap-4;
    }

    p {
      margin: $gap-0;
    }

    p:first-of-type {
      font-weight: $font-weight-700;
    }
  }

  .asideContainer {
    max-width: 37%;
    height: fit-content;
    padding: $gap-5;
    border: $border-width-1 solid map.get($primary, primary20);
    border-radius: $border-radius-default;

    h3 {
      margin: $gap-0;
      font-weight: $font-weight-700;
    }

    p {
      margin: $gap-2 $gap-0;
    }
  }

  @media (max-width: $screen-width-1024) {
    flex-direction: column;
    margin-top: $gap-5;

    .asideContainer {
      max-width: unset;
    }
  }
}

.radioButtonGroupFieldset {
  flex-direction: row;
  margin-top: $gap_4;
}

.radioButtonImage {
  width: $icon-size-regular;
  height: $icon-size-regular;
}
