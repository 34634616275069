@use "sass:map";
@import "../../../styles/constants/colors";
@import "~@oup/shared-front-end/src/styles/breakpoints";
@import "~@oup/shared-front-end/src/styles/themes/base/sizes";
@import "~@oup/shared-front-end/src/styles/themes/base/typography";


$color-draft: rgba(219 110 0 / 100%);
$color-active-upcoming: rgba(43 130 80 / 100%);
$color-completed: rgba(43 130 80 / 100%);
$color-text-default: rgba(51 51 51 / 100%);
$font-size-default: 1rem;
$font-weight-normal: 400;
$font-weight-bold: 700;
$line-height-default: 1.5rem;
$letter-spacing-default: -0.02em;
$one-px: 0.063rem;

hr.separator {
  margin: 2.5rem 0;
}

.placementTestItemRow {
  display: grid;
  width: 100%;
  align-items: center;
  padding: 0.75rem 0.938rem;
  border-radius: 0.313rem;
  gap: 1rem;
  grid-template-columns: 0.2fr 2fr 1.6fr 0.2fr;
  transition: background-color 0.3s ease;

  @media screen and (max-width: ($screen-width-1024 - $one-px)) {
    grid-template-columns: 0.2fr 2fr 1.55fr 0.25fr;
  }

  &.placementTestItemRowMyTasks {
    grid-template-columns: 0.2fr 2fr 0.8fr;

    .placementTestName {
        a {
          svg {
            fill: $color-orb-bright-blue;
          }
        }
      }
  }

  &.placementTestItemRowCompletedTasks {
    grid-template-columns: 0.2fr 2fr 1fr 0.1fr;
  }

  &:hover {
    background-color: #e5e9ed;
  }

  .placementTestItemColumn {
    .processingSpinner {
      display: flex;
      align-items: center;
      padding: 0;

      div:first-of-type {
        display: flex;
        width: 1.5rem;
        padding: 0;
        margin: 0;
      }

      svg {
        width: $icon-size-regular;
      }

      p {
        max-width: 7.375rem;
        margin-left: 1.1rem;
        font-size: $font-size-xsmall;
        font-weight: $font-weight-700;
        text-align: $text-align-left;
      }

      @media screen and (max-width: ($screen-width-840 - $one-px)) {
        p {
          display: none;
        }
      }
    }

    .thumbnail {
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        width: 3.125rem;
        height: 3.125rem;

        &.warningIcon, &.loadingIcon {
          width: 1.5rem;
          height: 1.5rem;
        }
      }
    }

    .orgName {
      font-weight: 600;

      svg {
        max-width: 1rem;
        margin-right: 0.25rem;
      }
    }

    .dateCreated {
      font-size: $font-size-default;
      font-weight: $font-weight-normal;
      letter-spacing: $letter-spacing-default;
      line-height: $line-height-default;
      text-align: left;

      &.DRAFT {
        color: $color-draft;
      }

      &.ACTIVE,
      &.UPCOMING {
        color: $color-active-upcoming;
      }

      &.COMPLETED {
        color: $color-completed;
      }
    }
  }

  .placementTestName {
    font-weight: 600;
  }

  .testStatus {
    display: inline-flex;
    align-items: center;
    padding: 0 0.5rem;
    border: 0.063rem solid;
    border-radius: 0.625rem;
    margin-right: 0.25rem;
    font-size: 0.813rem;
    font-weight: 600;

    &.DRAFT,
    &.PROCESSING,
    &.UPCOMING {
      border-color: $color-draft;
    }

    &.ACTIVE {
      border-color: $color-orb-bright-blue;

      &::before {
        display: block;
        width: 0.625rem;
        height: 0.625rem;
        border-radius: 50%;
        margin-right: 0.313rem;
        background-color: $color-orb-bright-blue;
        content: '';
      }
    }

    &.COMPLETED {
      border-color: $color-completed;
    }
  }

  .dropdownMenu {
    justify-self: end;

    .placementTestDropdown {
      position: relative;

      button:not(:focus) {
        border-top: none;
        box-shadow: none;
      }
    }
  }
}

.placementTestItemDateCreatedColumn {
  display: flex;
  justify-content: end;
  gap: 1rem;

  @media screen and (max-width: ($screen-width-840 - $one-px)) {
    justify-content: end;
  }

  .sessionDates {
    display: flex;
    flex-direction: column;
  }

  .draftIncomplete {
    display: flex;
    width: 7.5rem;
    flex-direction: column;
    align-self: center;
    font-size: 0.75rem;

    @media screen and (max-width: ($screen-width-840 - $one-px)) {
      display: none;
    }
  }

  .listingContainer {
    display: flex;
    max-width: 18.75rem;
    justify-content: flex-end;
    gap: 1.5rem;

    @media screen and (max-width: ($screen-width-840 - $one-px)) {
      display: none;
    }

    .listingColumn {
      width: 6.25rem;

      .label {
        font-size: 0.75rem;
        font-weight: 400;
        line-height: 0.9rem;
      }

      .result {
        font-size: 1rem;
        font-weight: 600;
        line-height: 1.5rem;
      }
    }
  }
}

.scoreContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  span {
    font-size: 0.875rem;
    font-weight: $font-weight-normal;
    line-height: 1rem;
  }

  .score {
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.5rem;
  }
}

.summaryTable {
  table {
    width: 100%;
    padding: 0.313rem;
    border: 0.063rem solid map.get($background-and-border-colors, border);
    border-radius: 0.625rem;
    margin-bottom: 1rem;
    border-spacing: 0;
  }

  tr {
    &:not(:first-of-type) {
      td {
        border-top: 0.063rem solid map.get($background-and-border-colors, border);
      }
    }

    td {
      position: relative;
      padding: 0.75rem 1rem;
      color: map.get($background-and-border-colors, background-light-blue);

      &:first-of-type {
        width: 40%;
        padding-right: 1.25rem;
        font-size: 0.875rem;
        font-weight: bold;
      }

      span {
        display: block;
      }
    }
  }

  .studentsAndLicencesContainer {
    display: flex;
    flex: 2;
    align-items: center;
    padding: 0.625rem 0;
    font-size: 1rem;
    gap: 0.625rem;

    .studentsAndLicencesTitle {
      margin: 0;
      color: map.get($primary, primary100);
      font-weight: 600;
    }

    p + p {
      margin-top: 0;
    }
  }

  .studentsIcon {
    width: 1.5rem;
    height: 1.5rem;
  }

  .licencesIcon {
    width: 2.188rem;
    height: 2.188rem;
  }

  .arrowRightIcon {
    position: absolute;
    top: 50%;
    right: 0;
    max-width: 1.5rem;
    transform: translateY(-50%);
  }

  .locked {
    td {
      &:last-of-type {
        padding-left: 2.5rem;
      }

      .lockIcon {
        position: absolute;
        top: 50%;
        left: 0;
        max-width: 1.5rem;
        margin-right: 0.313rem;
        transform: translateY(-50%);
        vertical-align: middle;

        path:last-of-type {
          fill: inherit;
        }
      }
    }
  }
}
