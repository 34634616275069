@use "sass:map";
@import "~@oup/shared-front-end/src/styles/themes/base/colors";
@import "~@oup/shared-front-end/src/styles/themes/base/sizes";
@import "~@oup/shared-front-end/src/styles/breakpoints";
@import "~@oup/shared-front-end/src/styles/themes/base/typography";
@import "~@oup/shared-front-end/src/styles/mixins/sr-only";

.wordOfTheDayArea {
  display: flex;
  flex-flow: column;
  align-items: stretch;
  justify-content: flex-start;
  padding: $gap-4;
  gap: $gap-2;
  text-align: center;

  .emptyStateContainer {
    border-radius: $gap-2;
    background-color: map.get($base, white100);
  }

  .wordOfTheDayContent{
    padding: $gap-2;
    border-radius: $gap-2;
    background-color: map.get($base, white100);

    .srOnly {
      @include sr-only;
    }

    .wordHeader {
      width: 100%;
      max-width: fit-content;
      height: auto;
    }

    .word{
      padding-bottom: $gap-1;
      text-align: left;

      a {
        text-decoration: none;
        color: map.get($heritage, scholar-purple100);
        font-family: $font-family-display-font;
        font-size: $font-size-xlarge;
        font-weight: $font-weight-400;
        line-height: $line-height-110;
      }
    }

    .levelPill {
      display: flex;
      flex-flow: row;
      justify-content: flex-start;
      margin-bottom: $gap-3;
      gap: $gap-2;

      .level {
        padding: $gap-1;
        border-radius: $gap-2;
        background-color: map.get($neutral, neutral-blue30);
        color: map.get($primary, primary70);
        font-family: $font-family-text-font;
        font-size: $font-size-small;
        font-weight: $font-weight-700;
        line-height: $line-height-100;
      }

      .category {
        color: map.get($primary, primary70);
        font-family: $font-family-text-font;
        font-weight: $font-weight-400;
        letter-spacing: $letter-spacing-0;
        line-height: $line-height-120;
        size: $font-size-base;
      }
    }

    .pronunciations {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: $gap-2;
      gap: $gap-2;

      button {
        max-width: 200px;
        flex: 1;
      }
    }

    .world {
      margin-top: $gap-2;
      margin-bottom: $gap-2;
      color: map.get($primary, primary70);
      font-family: $font-family-text-font;
      font-size: $font-size-base;
      font-weight: $font-weight-400;
      letter-spacing: $letter-spacing-0;
      line-height: $line-height-120;
    }
  }
}
