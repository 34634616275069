@use "sass:map";
@import "~@oup/shared-front-end/src/styles/themes/base/colors";
@import "~@oup/shared-front-end/src/styles/breakpoints";
@import "~@oup/shared-front-end/src/styles/themes/base/sizes";

.wizardContainer {
  position: fixed;
  z-index: 104;
  top: 0;
  left: 0;
  width: 100vw;
  margin: 0;
  background-color: map.get($base, white100);

  .header {
    width: 100%;
    padding: 1.5rem 3.75rem;
    background-color: map.get($base, white100);

    @media screen and (max-width: $screen-width-840) {
      padding: 1.5rem;
    }

    .headerContainer, .topContainer {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .title {
        flex: 1;
        font-size: 1rem;

        span {
          font-weight: bold;
        }
      }

      .helpAndSupport {
        display: flex;
        height: 100%;
        align-items: center;
        margin: auto 0;
        margin-left: auto;
        color: map.get($primary, primary100);
        gap: 1.5rem;

        a {
          display: inline-flex;
        }

        svg {
          width: 1.25rem;
          height: 1.25rem;
          fill: map.get($primary, primary100);
        }

        .helpSupportText {
          display: block;
          padding-left: 0.625rem;
          line-height: 1.25rem;
        }
      }
    }

    .progressContainer {
      margin-top: 1.5rem;
    }
  }

  .footer {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    width: 100%;
    height: 5.625rem;
    align-items: center;
    justify-content: flex-end;
    padding: 1.5rem 3.75rem;
    border-top: 1px solid map.get($primary, primary20);
    background: map.get($base, white100);
    gap: 1rem;

    @media screen and (max-width: $screen-width-840) {
      padding: 1.5rem;
    }
  }

  .multipleButtonsFooter {
    justify-content: space-between;
  }

  .step {
    display: flex;
    width: 100%;
    max-width: 55rem;
    height: 100%;
    align-items: flex-start;
    padding: $gap-6 $gap-5 $gap-5 $gap-6;
    gap: 1.5rem;

    &.centered {
      flex-direction: column;
      align-items: center;
    }

    @media screen and (max-width: $screen-width-840) {
      flex-direction: column;
      padding: 5rem 1.5rem;
    }

    .stepContent {
      h1 {
        max-width: 31.25rem;
        padding: 0;
        margin: 0 0 0.625rem;
        font-size: 2.125rem;
        font-weight: bold;
      }

      h2 {
        color: #666;
        font-size: 1.313rem;
        font-weight: normal;
        line-height: 1.969rem;
      }
    }
  }

  .imageContainer {
    display: flex;
    width: 40%;
    align-items: flex-start;
    justify-content: center;
    margin-top: 1.5rem;
    margin-bottom: 9rem;

    img {
      width: 100%;
    }

    @media screen and (max-width: $screen-width-840) {
      width: 100%;
      align-items: normal;
      margin-bottom: 1rem;

      img {
        max-width: 18.75rem;
        max-height: 18.75rem;
      }
    }
  }
}
