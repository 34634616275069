@import "../../../styles/constants/colors";
@import "../../../styles/constants/breakpoints";
@import "@oup/shared-front-end/src/styles/themes/base/sizes";

.dropDownMenu {
  box-shadow: none;
}

.classesContainer {
  position: relative;
  min-height: 4.6875rem;
  padding: 0.75rem;
  margin: 0.1875rem 0 0 0;
  border-radius: 0.3125rem;

  .classRow {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &.active {
    background-color: map-get($secondary-colors, hub_light_grey);
  }

  &:hover {
    background-color: map-get($secondary-colors, hub_light_grey);
    cursor: pointer;
  }

  &.classArchived:hover {
    cursor: default;
  }

  .dropDownMenu {
    button {
      box-shadow: none;
    }
  }

  .leftContainer svg {
    border: 1px solid $color-light-grey;
    fill: $color-white;
  }

  .statusContainer {
    display: flex;
    width: 25%;
    align-items: center;
    text-align: left;

    .textContainer span:first-child {
      display: block;
      color: map-get($typography-colors, sub-text);
      font-size: 0.8125rem;

      @media #{$phoneMedia} {
        display: none;
      }
    }

    .textContainer span:last-child {
      color: map-get($hubColors, hub_people_border);
      font-size: 1rem;

      @media #{$phoneMedia} {
        display: none;
      }
    }

    .svgContainer svg {
      width: 1.6rem;
      margin-right: 0.75rem;
    }

    @media #{$phoneMedia} {
      width: initial;
    }
  }

  .leftContainer {
    display: flex;
    width: 60%;
    align-items: center;

    @media #{$phoneMedia} {
      width: initial;
    }

    .classInfoDot {
      display: flex;
      min-width: 1.6rem;
      max-width: 1.6rem;
      height: 1.6rem;
      box-sizing: content-box;
      align-items: center;
      justify-content: center;
      padding: 0.8rem;
      margin: 0 0.75rem 0 0;
      background-color: map-get($secondary-colors, classroom);
      border-radius: 50%;

      svg {
        width: 100%;
        border: none;
      }

      @media #{$phoneMedia} {
        width: 1.3rem;
        height: 1.3rem;
        padding: 1.3rem;
      }
    }

    .classInfoContainer div:first-child {
      span {
        color: map-get($primary-colors, primary);
        font-size: 1rem;
      }
    }

    .classInfoContainer div:last-child {
      span {
        color: map-get($typography-colors, sub-text);
        font-size: 0.8125rem;
      }
    }

    &:hover .classInfoContainer div:first-child {
      span {
        color: map-get($primary-dark-colors, primary-dark);
      }
    }
  }

  .rightContainer {
    width: 15%;
    text-align: right;

    @media #{$phoneMedia} {
      display: none;
    }
  }

  &.rowWithFourColumns {
    .leftContainer {
      width: 45%;

      @media #{$phoneMedia} {
        width: initial;
      }
    }

    .statusContainer {
      width: 20%;
    }

    .classCodePlaceholder {
      width: 20%;
    }

    .dropdownPlaceholder {
      width: 45px;
    }
  }

  .bottomClassCodeContainer {
    display: flex;
    flex-wrap: wrap;
    margin-left: 3.95rem;
    color: map-get($hubColors, dark-gray);
    font-size: 13px;

    @media #{$phoneMedia} {
      margin-left: 4.9rem;
    }
  }

  &.classArchived {
    .classInfoDot {
      opacity: 0.5;
    }

    .statusContainer {
      .textContainer span:last-child {
        color: map-get($validation-colors, error);
      }
    }
  }

  &.maximumSelectionReached {
    .classInfoDot {
      opacity: 0.5;
    }
  }

  .checkboxContainer {
    padding-inline: $gap-3;
  }

  &.active {
    background-color: map-get($secondary-colors, hub_light_grey);
  }
}
