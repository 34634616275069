@use "sass:map";
@import '~@oup/shared-front-end/src/styles/themes/base/sizes';
@import '~@oup/shared-front-end/src/styles/themes/base/typography';
@import '~@oup/shared-front-end/src/styles/themes/base/colors';
@import "~@oup/shared-front-end/src/styles/box-shadow";

$child-contaier-width-limit: 3.75rem;
$container-min-width: 5.4375rem;
$container-min-height: 4.5rem;
$title-line-clamp: 2;
$subtitle-line-clamp: 2;
$no-subtitle-line-clamp: 4;

.container {
  display: flex;
  min-width: $container-min-width;
  min-height: $container-min-height;
  align-items: center;
  padding: $gap-3;
  border-width: $border-width-1;
  border-style: solid;
  border-color: map.get($primary, primary20);
  border-radius: $gap-3;
  background-color: map.get($base, white100);
  color: map.get($primary, primary100);
  font-family: $font-family-text-font;
  gap: $gap-3;

  &:hover {
    background-color: map.get($primary, primary10);
  }

  &:focus {
    box-shadow: $box-shadow-focus;

    outline: none;
  }
}

.childContainer {
  display: flex;
  overflow: hidden;
  min-width: $child-contaier-width-limit;
  max-width: $child-contaier-width-limit;
  justify-content: center;
  border-radius: $gap-2;
}

.title {
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  font-size: $font-size-base;
  font-weight: $font-weight-600;
  -webkit-line-clamp: $title-line-clamp;
  line-clamp: $title-line-clamp;
  line-height: $line-height-default-150;

  &.noSubtitle {
    -webkit-line-clamp: $no-subtitle-line-clamp;
    line-clamp: $no-subtitle-line-clamp;
  }
}

.subtitleContainer {
  display: inline-block;
}

.subtitle {
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  font-size: $font-size-xsmall;
  font-weight: $font-weight-400;
  -webkit-line-clamp: $subtitle-line-clamp;
  line-clamp: $subtitle-line-clamp;
  line-height: $line-height-120;

  span {
    display: inline-block;
  }
}
