@use "sass:map";
@import "~@oup/shared-front-end/src/styles/themes/base/colors";
@import "~@oup/shared-front-end/src/styles/box-shadow";
@import "~@oup/shared-front-end/src/styles/themes/base/sizes";
@import "~@oup/shared-front-end/src/styles/breakpoints";
@import "~@oup/shared-front-end/src/styles/themes/base/typography";

* {
  box-sizing: border-box;
}

.containerWrapper {
  margin: 0 auto;
}

.dashboardContainer {
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  justify-content: center;
  padding-right: $gap-5;
  padding-bottom: $gap-5;
  padding-left: $gap-5;
  margin: auto;
  gap:$gap-4;


  .lastOpenArea, .wordOfTheDay, .myTasksDashboard {
    width: 100%;
    max-width: 100%;
    border-radius: $gap-2;
    background-color: map.get($neutral, neutral-blue30);
  }

  .dashboardItemMedium {
    width: auto;
  }


  &.dashboardWithTasks {
    justify-content: center;

    .dashboardItemMedium {
      width: 100%;
    }
  }

}

@media (min-width: $screen-width-840) {
    .dashboardContainer {
      max-width: 64rem;
      flex-flow: row wrap;
      gap: $gap-5;

      .wordOfTheDay{
        max-width: 19rem;
      }

      &.dashboardWithTasks {
        justify-content: left;

        .wordOfTheDay{
          order: 1;
        }

        .lastOpenArea {
          order: 2;
        }

        .myTasksDashboard {
          order:0;
        }

        .dashboardItemMedium {
          max-width: calc(100% - 19rem - $gap-5);
        }
      }
    }
}
