@use "sass:map";
@import "~@oup/shared-front-end/src/styles/breakpoints";
@import "~@oup/shared-front-end/src/styles/themes/base/sizes";
@import "~@oup/shared-front-end/src/styles/themes/base/colors";
@import "~@oup/shared-front-end/src/styles/themes/base/typography";

.heading {
  font-family: $font-family-display-font;
  padding: 0;
}

.radioButtonsContainer {
  flex-direction: row;

  @media (max-width: $screen_width_600) {
    flex-direction: column;
  }
}

.chooseRoleRadioButton svg {
  width: $icon_size_medium;
  height: $icon_size_larger;
  color: map.get($primary, primary100);
  fill: map.get($primary, primary100);
}

.chooseAgeRadioButton {
  width: 19rem;
  height: 12rem;
}

.wizardStep {
  padding-right: $gap-1;
  padding-left: $gap-1;
}

.helpButtonLarge {
  display: flex;

  @media (max-width: $screen-width-840) {
    display: none;
  }
}

.helpButtonSmall {
  display: flex;

  @media (min-width: $screen-width-840) {
    display: none;
  }
}

.helpIconLoggedOut :nth-child(2) {
  fill: map.get($primary, primary100);
}

.outerContainer {
  padding: $gap-6;
}

.innerContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: $gap-5;
  gap: 3rem;
}
