@use "sass:map";
@import "~@oup/shared-front-end/src/styles/themes/base/colors";
@import "~@oup/shared-front-end/src/styles/box-shadow";
@import "~@oup/shared-front-end/src/styles/themes/base/sizes";
@import "~@oup/shared-front-end/src/styles/breakpoints";
@import "~@oup/shared-front-end/src/styles/themes/base/typography";

.heading {
  margin: 0;
  color: map.get($primary, primary100);
  font-family: $font-family-text-font;
  font-size: $font-size-base;
  font-weight: $font-weight-700;
  line-height: $line-height-120;
}

.lastOpenArea {
  display: flex;
  flex-flow: column;
  align-items: stretch;
  justify-content: flex-start;
  padding: $gap-4;
  gap: $gap-3;

  h1 {
    padding: $gap-0 $gap-2;
  }

  .icon {
    width: $gap-6;
    height: $gap-6;
    padding: $gap-3;
    border-radius: $gap-3;
    background-color: map.get($modern, isadora-pink);
  }

  .image {
    width: 100%;
    height: auto;
  }

  .emptyStateContainer {
    padding: $gap-2;
    border-radius: $gap-2;
    background-color: map.get($base, white100);
  }

}

.lastOpenAreaContent {
  border-radius: 1rem;
  background-color: map.get($base, white100);

  >div {
    padding: 0;
  }
}
