@use "sass:map";
@import "@oup/shared-front-end/src/styles/themes/base/colors";
@import "@oup/shared-front-end/src/styles/themes/base/typography";

.extraTimeContainer {
  p {
    margin-bottom: 0.5rem;
  }

  .link {
    position: relative;
    display: inline-flex;
    align-items: center;
    margin-top: 0.625rem;
    color: map.get($primary, primary100);
    font-weight: $font-weight_700;
    text-decoration: underline;

    svg {
      width: 1.3rem;
      height: 1.3rem;
    }

    &:hover {
      background-color: map.get($primary, primary10);
    }
  }

  h3 {
    font-weight:$font-weight_700;
  }

}
