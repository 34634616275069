@import "../../../styles/constants/breakpoints";
@import "~@oup/shared-front-end/src/styles/themes/base/typography";
@import "~@oup/shared-front-end/src/styles/themes/base/sizes";
@import "~@oup/shared-front-end/src/styles/themes/base/colors.scss";
@import "~@oup/shared-front-end/src/styles/box-shadow";
@import "../../../styles/constants/colors";

.secondaryLevelCourses {
  width: 100%;

  .secondaryLevelCourse {
    display: flex;
    min-height: 3.75rem;
    align-items: center;
    padding: $gap-3 18px;
    margin-left: 3px;
    cursor: pointer;
    font-family: $font-family-text-font;
    font-weight: $font-weight-400;
    letter-spacing: $letter-spacing-0;
    line-height: $line-height-120;

    .labelsContainer {
      margin-bottom: 0.35rem;
    }

    .comingSoonLabel {
      margin-bottom: 0.2rem;
      background-color: map-get($primary, primary100);
    }

    &:hover {
      background-color: map-get($primary, primary10);
      mix-blend-mode: multiply;
    }

    &:focus {
      box-shadow: $box-shadow-focus-inset;
    }

    &:hover,
    &:hover:focus {
      background-color: map-get($primary, primary10);
      mix-blend-mode: multiply;
    }

    .secondaryLevelHiddenCourses {
      display: flex;
      width: 1.875rem;
      height: 1.875rem;
      align-items: center;
      justify-content: center;
      border: 1px solid map-get($primary, primary20);
      margin-right: $gap-3;
      background-color: map-get($modern, isadora-pink);
      border-radius: 100%;

      > div {
        color: map-get($primary, primary100);
        font-family: $font-family-text-font;
        font-weight: $font-weight-400;
        letter-spacing: $letter-spacing-0;
        line-height: $line-height-120;
      }
    }

    .imgContainer {
      overflow: hidden;
      width: 1.875rem;
      height: 1.875rem;
      img {
        width: 100%;
        height: 100%;
        border: 1px solid map-get($primary, primary20);
        border-radius: 100%;
        object-fit: cover;
      }

      div {
        display: flex;
        overflow: hidden;
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: center;
        background-color: map-get($primary, primary100);
        border-radius: 100%;

        svg {
          width: 100%;
          height: 100%;
          border-radius: 50%;
          fill: map-get($base, white100);
        }
      }
    }

    .textContainer {
      margin-left: $gap-3;

      .course,
      .level {
        overflow: hidden;
        width: 12.5rem;
        text-overflow: ellipsis;
        white-space: nowrap;

        @media #{$phoneMedia} {
          width: calc(100vw - 6.875rem);
        }
      }

      .course:only-child {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        white-space: initial;
      }

      .level {
        margin-top: 4px;
        color: map-get($primary, primary100);
        font-size: $font-size-xsmall;
      }

      .newContentlabelPill {
        background-color: map-get($hubColors, hub_summer_yellow);
        width: max-content;
        margin-bottom: 1rem;
        padding: 0px 8px 0px 8px;
        gap: 10px;
        border-radius: 10px;
        opacity: 0px;
        margin: 0;
        margin-bottom: 1rem;
        span {
          color: map-get($primary-colors, primary);
          font-size: 13px;
          font-weight: 400;
          line-height: 19.5px;
          letter-spacing: -0.017em;
          text-align: left;
        }
      }
    }
  }
}
