@use "sass:map";
@import "~@oup/shared-front-end/src/styles/themes/base/colors";
@import "~@oup/shared-front-end/src/styles/themes/base/sizes";
@import "~@oup/shared-front-end/src/styles/themes/base/typography";
@import "~@oup/shared-front-end/src/styles/breakpoints";

.stepContainer {
  display: flex;
  width: 100%;
  height: 100%;

  @media (max-width: $screen-width-600) {
    height: auto;
  }

  .introStep {
    border-radius: $border-radius-xlarge;
    background-color: map.get($modern, kipper-yellow);

    @media (max-width: $screen-width-600) {
      flex-direction: column;
    }
  }

  .textContainer {
    display: flex;
    flex-direction: column;
    gap: $gap-6;

    @media (max-width: $screen-width-600) {
      gap: $gap-4;
    }

    ol {
      margin-left: $gap-4;

      li {
        display: list-item;
        margin-bottom: $gap-3;

        &::marker {
          font-weight: $font-weight-700;
        }

        p:first-of-type {
          font-weight: $font-weight-700;
        }
      }
    }
  }
}
