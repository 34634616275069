@use "sass:map";
@import "~@oup/shared-front-end/src/styles/themes/base/colors";
@import "~@oup/shared-front-end/src/styles/themes/base/sizes";
@import "~@oup/shared-front-end/src/styles/themes/base/typography";

.stepContainer {
  display: flex;
  width: 100%;
  height: 100%;
}

.wizardHero {
  background-color: map.get($neutral, neutral-blue100);

  ol {
    display: flex;
    flex-direction: column;
    margin-left: $gap-4;
    font-weight: $font-weight-700;
    gap: $gap-3;

    li {
      display: list-item;
    }
  }
}
