@import "~@oup/shared-front-end/src/styles/themes/base/typography";
@import "~@oup/shared-front-end/src/styles/themes/base/colors";
@import "~@oup/shared-front-end/src/styles/themes/base/sizes";

.kipperYellow {
  background-color: map-get($modern, kipper-yellow);
}

.tasks {
  display: flex;
  flex-direction: column;
  width: 100%;

  .banner {
    margin:  $gap-5;

    h1 {
      text-transform: capitalize;
    }
  }

  .content {
    margin: $gap-4 $gap-6;

    h2 {
      margin: $gap-4 $gap-5;
    }

    h4 {
      font-weight: bold;
      margin: $gap-2 $gap-5;
    }
  }
}
