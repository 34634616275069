@use "sass:map";
@import "~@oup/shared-front-end/src/styles/breakpoints";
@import "~@oup/shared-front-end/src/styles/themes/base/colors";
@import "~@oup/shared-front-end/src/styles/themes/base/sizes";
@import "~@oup/shared-front-end/src/styles/themes/base/typography";

.stepContainer {
  display: flex;
  max-width: 63.5rem;
  flex-direction: row;
  padding: $gap-5;
  gap: $gap-5;

  @media (max-width: $screen-width-600) {
    height: auto;
    flex-direction: column;
    margin-top: $gap-0;
  }

  .heading {
    padding: 0;
    font-family: $font-family-display-font;
  }

  .subHeading {
    padding-left: 0;
  }

  .leftColumnContainer {
    display: flex;
    width: 100%;
    flex-direction: column;
    padding: $gap-5 0;
    gap: 2rem;
  }

  .rightColumnContainer {
    width: 50%;
    padding: $gap-5 0;

    .classCodeInfoContainer {
      display: flex;
      flex-direction: column;
      padding: $gap-5;
      border: 1px solid map.get($primary, primary20);
      border-radius: $border-radius-default;
      gap: $gap-2;

      p span {
        margin: $gap-0 $gap-1;
      }
    }

    @media (max-width: $screen-width-600) {
      width: 100%;
    }
  }

  .boldText {
    font-weight: $font-weight-700;
  }

  .studentsListContainer {
    height: auto;
    margin-top: $gap-4;
  }

  .studentItemContainer {
    position: relative;
    padding: $gap-2;
    margin: $gap-2 $gap-0 $gap-4;

    &:hover {
      border-radius: 5px;
      background-color: map.get($primary, primary20);
    }

    &:not(:last-of-type)::after {
      position: absolute;
      bottom: -8px;
      left: 0;
      width: 100%;
      height: 0.5px;
      background-color: map.get($primary, primary20);
      content: '';
    }
  }
}
