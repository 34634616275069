@import "@oup/shared-front-end/src/styles/themes/base/typography";
@import "@oup/shared-front-end/src/styles/themes/base/sizes";
@import "~@oup/shared-front-end/src/styles/breakpoints";

.placementTestLanguageVariationColumn {
  display: block;

  h3 {
    font-weight: $font-weight_700;
  }
}

.languageVariationsOuterContainer {
 padding: $gap-4 0 $gap-4 0;
}

.languageVariationRadioButtonGroupContainer, .accentsRadioButtonGroupContainer, .placementTypeRadioButtonGroupContainer {
  display: flex;
  gap: $gap-3;
}

.placementTypeRadioButtonGroupContainer {
  @media (min-width: $screen-width-480) {
    flex-flow: row wrap;

    > label {
      flex-basis: 0;
      flex-grow: 1;
    }
  }
}

.languageVariationRadioButtonGroupContainer, .accentsRadioButtonGroupContainer {
  flex-direction: column;

  @media (min-width: $screen-width-480) {
    > label {
      flex-basis: 0;
      flex-grow: 1;
    }
  }
}
