@use "sass:map";
@import "~@oup/shared-front-end/src/styles/themes/base/colors";
@import "~@oup/shared-front-end/src/styles/breakpoints";
@import "~@oup/shared-front-end/src/styles/themes/base/typography";
@import "~@oup/shared-front-end/src/styles/themes/base/sizes";

.header {
  width: 100%;
  padding: $gap-5 3.75rem;
  background-color: map.get($base, white100);

  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .title {
    flex: 1;
    font-size: $font-size-base;

    span {
      font-weight: bold;
    }
  }

  .helpAndSupport {
    display: flex;
    height: 100%;
    margin: auto 0;
    margin-left: auto;
    color: map.get($primary, primary100);

    a {
      display: inline-flex;
    }

    svg {
      width: 1.25rem;
      height: 1.25rem;
      fill: map.get($primary, primary100);
    }

    .helpSupportText {
      display: block;
      padding-left: 0.625rem;
      line-height: 1.25rem;

      @media (max-width: $screen-width-480){
        display: none;
      }
    }
  }

  .closeButton {
    margin-left: 1.875rem;
  }

  @media (max-width: $screen-width-600) {
    padding: $gap-5;
  }
}
