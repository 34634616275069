@use "sass:map";
@import "~@oup/shared-front-end/src/styles/themes/base/colors";

.licenceCountingContainer {
  .studentsSelectorContainer {
    display: flex;
    width: 100%;
    flex-direction: column;
    padding: 0.625rem 0;
    border: 1px solid map.get($primary, primary20);
    border-radius: 0.625rem;


    .studentsSelector {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0.625rem;

      .segmentTitle {
        flex: 3;
        margin-left: 0.313rem;
        color: map.get($primary, primary100);
      }

      .numberOfStudentsInfo {
        display: flex;
        flex: 2;
        align-items: center;
        font-size: 1rem;

        .addStudents {
          margin: 0;
          color: map.get($primary, primary100);
          font-weight: 600;
        }
      }

      .chevronIcon {
        width: 1.5rem;
        height: 1.5rem;
        flex: 0.2;
      }

      &:hover {
        cursor: pointer;
      }
    }

    hr {
      margin: 0 0.625rem;
    }
  }

  h4 {
    margin-bottom: 1.5rem;
  }

  .validationMessageContainer {
    margin-bottom: 1.5rem;

    p > span {
      font-size: 0.75rem;
    }
  }
}
