@import "~@oup/shared-front-end/src/styles/themes/base/typography";
@import "~@oup/shared-front-end/src/styles/themes/base/sizes";

.searchControl {
  z-index: 2;
}

.manageAddedStudentsContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  gap: 0.5rem;

  th > div, th > div > div{
    border: none;
  }
}

.manageStudentsList {
  position: relative;

  .manageAddedStudentAddBtnContainer {
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    display: flex;
    width: 50%;
    justify-content: flex-end;

    h4 {
      font-weight: $font-weight-700;
    }
  }
}

.headingStyle {
  h2 {
    font-weight: $font-weight-700;
  }
}

.centerAddStudentsBtn {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;

}

.addStudentBtn {
  span {
    padding: 0.5rem
  }

}
