@import "../../../styles/constants/breakpoints.scss";
@import "~@oup/shared-front-end/src/styles/themes/base/typography";
@import "~@oup/shared-front-end/src/styles/themes/base/sizes";
@import "~@oup/shared-front-end/src/styles/themes/base/colors.scss";
@import "~@oup/shared-front-end/src/styles/box-shadow";
@import "../../../styles/mixins/hub/hub-button-focus";

.secondaryLevelContainer {
  overflow: auto;
  max-height: 0;
  pointer-events: none;
  transition: visibility 0.1s cubic-bezier(0, 1, 0, 1), max-height 0.3s cubic-bezier(0, 1, 0, 1);
  visibility: hidden;

  .selfTeacherLink {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    padding: 0.25rem 18px;
    margin-bottom: 15px;
    font-family: $font-family-text-font;
    font-size: $font-size-small;
    font-weight: $font-weight-700;
    letter-spacing: $letter-spacing-0;
    line-height: $line-height-120;
    text-decoration: underline;

    svg {
      width: 1.2rem;
      height: 1.2rem;
      box-sizing: content-box;
      margin-left: 0.1rem;
      transform: translateY(0.3rem);
    }

    span {
      &:focus {
        @include standart-focus-button();
      }

      &:hover {
        background-color: map-get($primary, primary10);
        color: map-get($hubColors, blue-color-dark);
        mix-blend-mode: multiply;
        text-decoration-thickness: 2px;
      }
    }
  }

  &.lastItemPadding {
    padding-bottom: 6.5rem;

    @media #{$smMedia} {
      padding-bottom: 3.5rem;
    }

    @media #{$phoneMedia} {
      padding-bottom: 5.5rem;
    }
  }

  &::-webkit-scrollbar {
    width: 0; /* Remove scrollbar space */
    background: transparent; /* Optional: just make scrollbar invisible */
  }

  .selfTeacherNoteContainer {
    background-color: map-get($primary, primary10);
    mix-blend-mode: multiply;
  }

  .selfTeacherButtonContainer {
    background-color: map-get($primary, primary10);
    mix-blend-mode: multiply;

    a:hover {
      background-color: map-get($primary, primary10);
      color: map-get($hubColors, blue-color-dark);
      mix-blend-mode: multiply;
    }
  }

  .selfTeacherNote {
    padding: $gap-3 18px;
    cursor: default;
    font-family: $font-family-text-font;
    font-size: $font-size-small;
    font-weight: $font-weight-400;
    letter-spacing: $letter-spacing-0;
    line-height: $line-height-120;

    span {
      font-weight: $font-weight-700;
    }
  }

  .secondaryLevelButton {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;

    &:hover {
      background-color: map-get($primary, primary10);
      mix-blend-mode: multiply;
    }

    &:hover,
    &:hover:focus {
      background-color: map-get($primary, primary10);
      mix-blend-mode: multiply;
    }

    .selfTeacherButton {
      font-family: $font-family-text-font;
    }

    .link {
      position: relative;
      display: flex;
      width: 100%;
      height: 3.75rem;
      flex-direction: column;
      justify-content: center;
      padding: $gap-3 18px;
      color: map-get($primary, primary100);
      outline: none;

      &:focus {
        box-shadow: $box-shadow-focus-inset();
      }

      .content {
        display: flex;
        width: 100%;
        flex: 1;
        align-items: center;

        svg {
          width: 1.5rem;
          height: 1.5rem;
          margin-right: $gap-3;
          margin-left: 5px;
          fill: map-get($primary, primary100);
        }
      }

      .selfTeacherContent {
        padding: 0.8rem 0;

        svg {
          width: 1.5rem;
          height: 1.5rem;
        }

        .iconContainer {
          width: 1.5rem;
          height: 1.5rem;
          margin: 0 1.125rem 0 0;
        }
      }

      .selfTeacher {
        display: flex;

        span {
          position: relative;
        }

        svg {
          width: 1.2rem;
          height: 1.2rem;
          box-sizing: content-box;
          margin-left: 0.1rem;
          transform: translateY(0.3rem);
        }
      }

      &::before {
        position: absolute;
        top: 0;
        left: 18px;
        width: 88%;
        height: 1px;
        background-color: map-get($primary, primary20);
        content: "";
      }
    }
  }
}

.secondaryLevelContainerOpen {
  max-height: calc(100vh - 4.8125rem);
  pointer-events: initial;
  transition: visibility 0s cubic-bezier(0, 1, 0, 1), max-height 0.3s cubic-bezier(0, 1, 0, 1);
  visibility: visible;

  @media #{$phoneMedia} {
    padding: 0;
  }
}
