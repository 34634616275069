@use "sass:map";
@import "~@oup/shared-front-end/src/styles/themes/base/sizes";
@import "~@oup/shared-front-end/src/styles/themes/base/typography";
@import "~@oup/shared-front-end/src/styles/breakpoints";
@import "~@oup/shared-front-end/src/styles/themes/base/colors";

.summaryDetails {
  padding: 2rem 2rem 0 0;

  .detailsRow {
    display: flex;
    margin-bottom: $gap-4;
    font-size: $font-size-base;
    line-height: $font-size-base;

    &:nth-of-type(2) {
      margin-top: $gap-2;
    }

    span {
      white-space: break-spaces;
    }

    .svgContainer {
      display: flex;
      margin-right: $gap-1;
    }
  }

  .infoIcon {
    width: $icon-size-small;
    height: $icon-size-small;
  }

  @media (max-width: $screen-width-480) {
    display: inline-block;
  }

  .boldText {
    font-weight: $font-weight-700;
  }

  .noStudentsInfoContainer {
    margin-bottom: $gap-4;
    margin-left: 1.25rem;
    font-size: $font-size-small;

    a {
      margin-left: $gap-1;
    }
  }
}

.summaryStepContainer {
  padding: $gap-6 $gap-5;

  @media (max-width: $screen-width-480) {
    flex-direction: column;
  }
}
