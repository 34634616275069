@use "sass:map";
@import "../../../styles/constants/colors";
@import "../../../styles/constants/breakpoints";
@import "../../../styles/mixins/hub/hub-button-focus";
@import "@oup/shared-front-end/src/styles/themes/base/typography";
@import "@oup/shared-front-end/src/styles/themes/base/sizes";

.notificationsColumn {
  .customiseToggle {
    display: flex;
    justify-content: start;
    margin-bottom: 1rem;

    .orgStatusLicenceItemTooltip {
      span {
        width: 13.125rem;
      }
    }

    .tooltipLabel {
      width: auto;
    }

    .infoIcon {
      width: 1.5em;
      height: 1.5em;
      margin-left: 0.625rem;
      color: map.get($hubColors, hub_madrid_orange);
      fill: map.get($hubColors, hub_madrid_orange);
    }

    .notificationText {
      display: inline-block;
      margin: 0 0 0 15px;
      font-weight: 600;

    }
  }

  .selectionContainer {
    .labelThinWeight {
      font-weight: 400;
    }
  }

  .noteText {
    font-weight: $font-weight-700;
  }
}

.notificationsAndRemindersContainer {
  padding: $gap-4;

  select {
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
