@import "~@oup/shared-front-end/src/styles/breakpoints";
@import "~@oup/shared-front-end/src/styles/themes/base/sizes";
@import "~@oup/shared-front-end/src/styles/themes/base/typography";

.heading {
  padding: 0;
  font-family: $font-family-display-font;
}

.stepContainer {
  display: flex;
  max-width: 63.5rem;
  flex-direction: row;
  padding: $gap-6 $gap-5;
  gap: $gap-5;

  @media (max-width: $screen-width-600) {
    height: auto;
    flex-direction: column;
    padding-top: $gap-0;
  }
}

.enterCodeContent {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: $gap-5 $gap-0;
  gap: 3rem;
  align-self: stretch;

  @media (max-width: $screen-width-600) {
    min-height: 18rem;
  }
}

.rightColumnContainer {
  width: 50%;
  padding: $gap-5 $gap-0;

  @media (max-width: $screen-width-600) {
    width: 100%;
  }
}

.linksContainer {
  display: flex;
  flex-direction: column;
  padding-top: $gap-2;

  span {
    margin-left: $gap-0;
  }

  span:first-of-type {
    margin-bottom: $gap-1;
  }
}
