@import "~@oup/shared-front-end/src/styles/themes/base/colors";
@import "~@oup/shared-front-end/src/styles/themes/base/sizes";

.pageHeroContainer {
  padding: $gap-5;
}

.neutralBlue {
  background-color: map-get($neutral, neutral-blue100);
}
