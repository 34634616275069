@use "sass:map";
@import "~@oup/shared-front-end/src/styles/themes/base/sizes";
@import "~@oup/shared-front-end/src/styles/themes/base/typography";
@import "~@oup/shared-front-end/src/styles/themes/base/colors";

.contentContainer {
  padding: $gap-4;

  .progressContainer {
    margin-top: $gap-5;

    p span:first-of-type {
      margin-right: 3px;
      font-weight: $font-weight-700;
    }

    .roundedProgressBar {
      border-radius: $border-radius-xlarge;

      &::-webkit-progress-bar {
        border-radius: $border-radius-xlarge;
      }

      &::-webkit-progress-value {
        border-radius: $border-radius-xlarge;
      }

      &::-moz-progress-bar {
        border-radius: $border-radius-xlarge;
      }
    }

    .warning {
      background-color: map.get($validation, validation-orange100);

      &::-webkit-progress-value {
        background-color: map.get($validation, validation-orange100);
      }

      &::-moz-progress-bar {
        background-color: map.get($validation, validation-orange100);
      }
    }
  }

  .linkContainer svg {
    width: $gap-3;
    height: $gap-3;
  }

  .userCountContainer {
    padding: $gap-4;
    border-radius: $border-radius-default;
    margin-top: $gap-4;
    background-color: map.get($primary, primary10);

    div {
      display: flex;
      justify-content: space-between;
      font-size: $font-size-small;
      line-height: $gap-5;

      span:first-of-type {
        text-transform: capitalize;
      }

      span:nth-of-type(2) {
        font-weight: $font-weight-700;
      }
    }
  }
}
