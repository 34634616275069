@import "../../styles/constants/breakpoints";
@import "~@oup/shared-front-end/src/styles/themes/base/sizes";

.hubClassLayout {
  display: flex;
  overflow: hidden;
  height: 98.5%;
  align-items: flex-start;

  .hubClassLayoutSubMenu {
    height: 100%;

    @media #{$phoneMedia} {
      width: 100%;
      padding: 12px;
      margin: 0;
    }
  }
}

.hubClassChildrenContainer {
  position: relative;
  width: 86%;
  max-width: 78vw;
  height: 100%;
  padding: 24px;
  padding-bottom: 5rem;

  &.navOpen {
    max-width: 62vw;
    transition: all 0.3s ease-in-out;

    &.gradebookPage {
      max-width: 70vw;
    }
  }

  @media #{$lgMedia} {
    max-width: 80vw;
  }

  @media #{$ipadAirMedia} {
    max-width: 72vw;
  }

  @media #{$smMedia} {
    padding: 16px;
    padding-bottom: 5rem;
  }

  @media #{$smMdMedia} {
    padding: 24px;
    padding-bottom: 5rem;
  }

  @media #{$phoneMedia} {
    width: 100%;
    max-width: 100%;
    min-height: 31rem;
    padding: 24px 12px;
    padding-bottom: 5rem;
    margin-bottom: 90px;
  }
}

.navigationChanges {
  .hubClassChildrenContainer {
    max-width: 100%;
    flex-grow: 1;
    padding-right: 0;
    padding-left: 0;

    &.gradebookPage {
      padding-top: $gap-0;
    }

    &.navOpen.gradebookPage {
      max-width: 100%;
    }
  }
}
