@import "../../../../styles/constants/colors";
@import "../../../../styles/constants/breakpoints.scss";
@import "../../../../styles/mixins/hub/hub-button-focus";

$color-draft: rgba(219, 110, 0, 1);
$color-active-upcoming: rgba(43, 130, 80, 1);
$color-completed: rgba(43, 130, 80, 1);

.modalWrapper {
  position: fixed;
  z-index: 102;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  width: 100%;
  min-height: 100vh;
  justify-content: center;
  padding: 0;
  margin: 0;
  background-color: transparent;
  opacity: 1;
  pointer-events: none;
  transition: 0.3s background-color, z-index ease-in-out;

  &.open {
    z-index: 999;
    background-color: $contrast-light-hover;
    pointer-events: unset;
    visibility: initial;
  }

  .modalContainer {
    position: absolute;
    top: -100%;
    left: 50%;
    background-color: $color-white;
    border-radius: 0.3125rem;
    transform: translate(-50%, -50%);
    transition: 0.3s top linear;

    &.animationFadeIn {
      top: 45%;
    }

    @media #{$xsMedia} {
      min-width: 20rem;
    }

    @media #{$mdMedia}{
      min-width: 30rem;
    }

    @media #{$lgMedia}{
      min-width: 40rem;
    }

    .modalHeader {
      padding: 1.5rem;
      text-align: right;

      button {
        width: 5.75rem;
        min-height: 2.5rem;

        &:hover,
        &:focus {
          background-color: map-get($secondary-colors, hub_light_grey);
          color: map-get($hubColors, hub_dark_blue);
          filter: brightness(100%);

          svg {
            fill: map-get($hubColors, hub_dark_blue);
          }
        }

        &:focus {
          @include standart-focus-button();
        }
      }
    }

    .modalContent {
      min-height: 18.75rem;
      padding: 0 1.25rem 0 1.25rem;

      .sessionTitlesContainer {
        display: flex;
        margin-bottom: 1.25rem;

        .testTypeCover {
          width: 5rem;
          height: 5.313rem;

          .productCover {
            height: 5rem;
            width: 5rem;
          }

          .iconContainer {
            width: 5rem;
            height: 5rem;
          }
        }

        .productTitles {
          display: flex;
          flex-direction: column;
          justify-content: center;
          margin-left: 0.625rem;

          .titleContainer {
            display: flex;
            align-items: center;

            .title {
              font-size: 1.5rem;
              font-weight: 700;
              color: map-get($primary-colors, primary);

              @media #{$xsMedia} {
                font-size: 1.3rem;
              }
            }

            .testStatus {
              border: 1px solid;
              padding: 0 0.5rem 0 0.5rem;
              height: 1.5rem;
              border-radius: 1.25rem;
              font-size: 1rem;
              font-weight: 700;
              display: inline-flex;
              align-items: center;
              margin-right: 0.625rem;

              &.DRAFT,
              &.UPCOMING {
                border-color: $color-draft;
              }

              &.ACTIVE {
                border-color: $color-orb-bright-blue;
                &::before {
                  content: '';
                  display: block;
                  width: 0.625rem;
                  height: 0.625rem;
                  border-radius: 50%;
                  background-color: $color-orb-bright-blue;
                  margin-right: 0.313rem;
                }
              }

              &.COMPLETED {
                border-color: $color-completed;
              }
            }
          }

          .productTitle {
            font-size: 1.3rem;
            font-weight: 400;
            color: map-get($hubColors, dark-gray);

            @media #{$xsMedia} {
              font-size: 1.1rem;
            }
          }
        }
      }

      .sessionDates {
        span {
          display: block;
          margin: 0.625rem 0;
          padding-top: 0.625rem;
          border-top: 1px solid map-get($cptColors, grey);
        }
      }

      .completedSessionBanner {
        display: flex;
        align-items: center;
        padding: 0 1.25rem;
        height: 2.5rem;
        background-color: map-get($core, background);
        border-radius: 0.5rem;

        .assessmentIcon {
          height: 1.5rem;
          width: 1.5rem;
        }

        p {
          font-size: 0.875rem;
          font-weight: 600;
          color: map-get($cptColors, dark_blue);
          margin-left: 0.625rem;
        }
      }
    }

    .modalFooter {
      display: flex;
      justify-content: space-between;
      padding: 1.5rem;
      border-top: 1px solid map-get($cptColors, grey);

      &.modalFooterDefault {
        button:focus {
          @include standart-focus-button();
        }

        button:first-of-type {
          padding: 0;
          text-decoration: underline;
          font-weight: bold;
          color: map-get($hubColors, hub_link);

          &:hover {
            color: map-get($hubColors, hub_dark_blue);
          }
        }

        button:last-of-type {
          border-bottom: 0;
          height: 3rem;
          border-radius: 0.5rem;
        }
      }

      &.modalFooterMyTasks {
        button:first-of-type {
          border-width: 1px;
        }
      }
    }
  }
}
