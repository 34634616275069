@import "../../../../styles/constants/colors";

.joiningCode {
  padding: 1rem;
  border: 1px solid map-get($background-and-border-colors, border);
  margin-bottom: 1rem;
  border-radius: 0.625rem;

  .joiningCodeLabel {
    font-weight: 700;
  }

  .joiningCodeContent {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    margin-bottom: 1rem;

    .joiningCodeLeft {
      .joiningCodeValue {
        font-size: 1.5rem;
      }
    }

    .joiningCodeRight {
      display: flex;
      flex-direction: row;
      gap: 0.5rem;
      position: relative;
      z-index: 1;
    }
  }

  .joiningCodeCopyAndShare {
    margin-bottom: 1rem;
  }
}
