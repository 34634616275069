@import "~@oup/shared-front-end/src/styles/breakpoints";
@import "~@oup/shared-front-end/src/styles/themes/base/sizes";
@import "~@oup/shared-front-end/src/styles/themes/base/typography";

.stepContainer {
  display: flex;
  flex-direction: column;
  padding: $gap-6 $gap-5 0 $gap-5;
  max-width: 40.5rem;
}

.heading {
  font-family: $font-family-display-font;
}

.radioButtonGroupFieldset {
  padding: 0 $gap-1 $gap-5;
}

.radioButtonGroupContainer {
  display: flex;
  flex-flow: column;
  padding: 0 $gap-1;
  gap: $gap-3;

  @media (min-width: $screen-width-600) {
    flex-flow: row wrap;

    > label {
      flex-basis: 0;
      flex-grow: 1;
    }
  }
}

.radioButtonImage {
  width: $icon-size-medium;
  height: $icon-size-medium;
}
