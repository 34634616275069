@import "../../../../src/styles/constants/colors";
@import "~@oup/shared-front-end/src/styles/themes/base/typography";
@import "~@oup/shared-front-end/src/styles/themes/base/sizes";

.productsHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #333333;
  max-width: 85.3%;
  font-weight: $font-weight-700;
  margin-left: 1rem;
  margin-bottom: 0.5rem;
}

.creditsAvailable {
  color: #333333;
  font-weight: 500;
  margin-left: 0.2rem;
  margin-top: 0.2rem;
  margin-bottom: 2.2rem;
}

.header {
  margin-left: -0.35rem;

  h1 {
    font-weight: $font-weight-600;
  }
}
